import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

const LINKS = [
  {
    name: "Blog - damiansowa.pl (in Polish and English)",
    url: "https://damiansowa.pl/",
  },
  {
    name: "Interview for justjoin.it (in Polish)",
    url: "https://justjoin.it/blog/holendrzy-sa-mistrzami-pracy-zespolowej-wywiad-z-damianem-sowa/",
  },
  {
    name: "Tech articles on medium.com",
    url: "https://medium.com/@damiansowa",
  },
  {
    name: "mffn company (my projects)",
    url: "https://mffn.pl/",
  },
  {
    name: "Expat Wallet",
    url: "https://expat-wallet.com/",
  },
  {
    name: "drivexam.com - learn for written driving exam in Asia",
    url: "https://www.drivexam.com",
  },
  {
    name: "japan.drivexam.com - learn for written driving exam in Japan",
    url: "https://www.driving-exam-thailand.com",
  },
  {
    name: "driving-exam-thailand.com - learn for written driving exam in Thailand",
    url: "https://www.japan.drivexam.com",
  },
  {
    name: "Booking helper - Google reviews for hotels (chrome extension for booking.com)",
    url: "https://chromewebstore.google.com/detail/booking-helper-google-rev/jdedooomfkfpaphmachcibnagigciagp",
  },
  {
    name: "Instagram - @niecnasowa",
    url: "https://www.instagram.com/niecnasowa/",
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/in/damian-sowa/",
  },
  {
    name: "Github - @niecnasowa",
    url: "https://github.com/niecnasowa",
  },
  {
    name: "mail - damian [at] sowa [dot] pm",
    url: undefined,
  },
]

const Index = () => (
  <>
    <SEO title="Damian Sowa | Links" />
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center max-w-3xl w-full px-3">
        <StaticImage
          src="../images/avatar.jpeg"
          alt="avatar"
          className="w-24 h-24 rounded-full mt-8"
        />
        <p className="mt-4 text-gray-700">Damian Sowa</p>
        <p className="mt-4 mb-6 text-gray-700">
          Programmer, digital nomad, entrepreneur.
        </p>
        {LINKS.map(({ name, url }) => (
          <a
            className="bg-gray-200 hover:bg-gray-300 focus:bg-gray-400 active:bg-gray-500 text-gray-700 w-full py-3 mb-4 flex justify-center rounded-lg text-center"
            href={url}
            target="_blank"
            rel="noopener"
          >
            {name}
          </a>
        ))}
      </div>
    </div>
  </>
)

export default Index
